<template>
  <div class="row">
    <div class="col-md-12">
      <vue-dropzone
        :options="dropzoneOptions"
        :useCustomSlot="true"
        :id="vid"
        ref="dropzoneUpload"
        v-on:vdropzone-sending="sendingFile"
        v-on:vdropzone-removed-file="onFileRemoved"
        v-on:vdropzone-complete="onComplete"
        v-on:vdropzone-processing="onFileProcessing"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            Drag & Drop or Click To Choose A File To Upload.
          </h3>
          <div class="subtitle">Up to 5MB files</div>
        </div>
      </vue-dropzone>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  name: "dropzone-upload",
  props: {
    data: {
      type: Array,
      default: () => [],
    },

    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      medias: [],
      processing: false,
      dropzoneOptions: {
        maxFilesize: 5,
        maxFiles: 10,
        url: `${process.env.VUE_APP_BASE_API}api/v1/chats/media/upload`,
        addRemoveLinks: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
    };
  },
  mounted () {
    if (this.files) {
      this.manuallyAddFile(this.files)
      this.medias = JSON.parse(JSON.stringify(this.files))
    }
  },
  watch: {
    data: {
      handler: function(newValue, oldValue) {
        if (oldValue.length === 0 && newValue && newValue.length) {
          this.manuallyAddFile(newValue);
        }
      },
    },
    processing: {
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit("processing", this.processing);
        }
      },
    },
  },
  computed: {
    vid() {
      return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10);
    },
  },
  methods: {
    sendingFile() {},

    manuallyAddFile(files) {
      files.forEach((file) => {
        var url = file.path;
        this.$refs.dropzoneUpload.manuallyAddFile(file, url);
      });
      this.medias = files;
      this.$emit("updated", { medias: this.medias });
    },

    removeAllFiles() {
      this.$refs.dropzoneUpload.removeAllFiles();
      this.medias = [];
      this.$emit("updated", { medias: this.medias });
    },

    onFileRemoved(file) {
      if (file.id) {
        this.medias = this.medias.filter((item) => item.id !== file.id);
      } else {
        if (file && file.uuid) {
          this.medias = this.medias.filter(
            (item) => item.uuid !== file.uuid
          );
        }
      }

      this.$emit("updated", { medias: this.medias });
    },

    onComplete(res) {
      if (res.status === "success" || res.manuallyAdded) {
        if (res.manuallyAdded) {
          this.medias.push(res);
        } else {
          const data = JSON.parse(res.xhr.response)["data"]["file"];
          data["uuid"] = res.upload.uuid;
          this.medias.push(data);
        }

        this.$emit("updated", { medias: this.medias });
        this.processing = false;
      } else if(res.status === "error"){
        Vue.$toast.open({
          message: "File is not acceptable. Up to 5MB.",
          type: "error",
        });
      }
    },

    onFileProcessing() {
      this.processing = true;
    },
  },
};
</script>

<style lang="scss"></style>
