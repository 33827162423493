<template>
  <div class="row">
    <div class="col-md-12 pt-1" v-if="convert && errors.length">
      <span class="d-block text-danger text-center font-weight-bold">{{ errorMsg }}</span>
    </div>
    <div class="col-md-12">
      <div class="texteditor-picker" :class="{
        active: active,
        'error': convert && errors.length
      }">
        <div ref="editor"><div style="font-size: 18px;"></div></div>
        <div v-if="showBottom"
          class="message-compose-wrapper border-top d-flex justify-content-space-between align-items-center">
          <div>
            <ul class="icon-ul">
              <li v-if="canUseAi">
                <span class="emoji-trigger icon stroke ai-content-icon" v-b-tooltip.hover title="AI Content"
                  @click="() => isShowAIContentModal = true"
                >
                  <i class="fa fa-magic"></i>
                </span>
                <AIContentModal
                  type="email"
                  :open="isShowAIContentModal"
                  :contact="contact"
                  :auto-generate="autoGenerate"
                  @closed="() => isShowAIContentModal = false"
                  @done="(data) => onAddAIContent(data)"
                ></AIContentModal>
              </li>
              <li>
                <span v-if="medias && medias.length" class="badge badge-primary">{{ medias.length }}</span>
                <span v-b-tooltip.hover :title="medias && medias.length
                  ? `Has Attachments Uploaded`
                  : `Upload Attachments`
                  " class="emoji-trigger icon stroke img-icon" :class="{ 'has-medias': medias && medias.length }"
                  @click="onOpenImageModal">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title />
                    <path class="path-style"
                      d="M23.5,1.5a1,1,0,0,0-1-1H4.5a1,1,0,0,0-1,1v18a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1Z" />
                    <path class="path-style"
                      d="M21,16.5,18.343,9.411a.5.5,0,0,0-.884-.1L14,14.5l-2.049-2.459a.5.5,0,0,0-.813.063L8.5,16.5" />
                    <circle class="path-style" cx="10" cy="5.997" r="1.5" />
                    <line class="path-style" x1="23.5" x2="3.5" y1="16.497" y2="16.497" />
                    <path class="path-style" d="M3.5,4.5h-2a1,1,0,0,0-1,1v17a1,1,0,0,0,1,1h17a1,1,0,0,0,1-1v-2" />
                  </svg>
                </span>
              </li>
              <li v-if="enabledTemplate">
                <span v-b-tooltip.hover title="Select Template" class="emoji-trigger icon stroke template-icon"
                  @click="onOpenModalTemplate">
                  <svg enable-background="new 0 0 21 21" height="21px" id="Layer_1" version="1.1" viewBox="0 0 21 21"
                    width="21px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="icon">
                      <path
                        d="M4.5,4.62V2.3c0,-0.44,0.36,-0.8,0.8,-0.8h10.203c0.318,0,0.623,0.126,0.849,0.351l2.797,2.797c0.225,0.225,0.351,0.53,0.351,0.849V18.7c0,0.44,-0.36,0.8,-0.8,0.8H5.3c-0.44,0,-0.8,-0.36,-0.8,-0.8v-0.34"
                        fill="none" class="path-style" />
                      <path d="M15.5,3v1.7c0,0.442,0.358,0.8,0.8,0.8h1.7" fill="none" class="path-style" />
                      <path d="M6.7,4.5h6.8M13.5,6.5h0.5M13.5,8.5h4M13.5,10.5h4M13.5,12.5h4M13.5,14.5h4M13.5,16.5h4"
                        fill="none" class="path-style" />
                      <rect fill="none" height="10" class="path-style" width="10" x="1.5" y="6.5" />
                      <polyline fill="none" points="3.5,9.48 3.5,8.5 9.5,8.5 9.5,9.48" class="path-style" />
                      <line fill="none" class="path-style" x1="6.5" x2="6.5" y1="14.5" y2="8.5" />
                      <line fill="none" class="path-style" x1="6" x2="6.98" y1="14.5" y2="14.5" />
                    </g>
                  </svg>
                </span>
              </li>
              <li v-if="enabledMergeField">
                <MergeFields :autoHide="true" :showArrow="false" placement="bottom" popoverClass="more-custom-popover"
                  :hideContactDetail="hideContactMergeFields" @onAdd="onAddMergeField" ref="mergeFieldObj">
                  <template slot="trigger">
                    <span v-b-tooltip.hover class="emoji-trigger icon stroke merge-filed-icon" title="Add Merge Fields">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <title />
                        <g data-name="Contact Info" id="Contact_Info">
                          <g data-name="&lt;Group&gt;" id="_Group_">
                            <g data-name="&lt;Group&gt;" id="_Group_2">
                              <path
                                d="M22.5,6.5V21A1.5,1.5,0,0,1,21,22.5H3A1.5,1.5,0,0,1,1.5,21V3A1.5,1.5,0,0,1,3,1.5H17.5Z"
                                data-name="&lt;Path&gt;" id="_Path_" class="path-style" />
                              <path d="M17.5,1.5V5A1.5,1.5,0,0,0,19,6.5h3.5" data-name="&lt;Path&gt;" id="_Path_2"
                                class="path-style" />
                            </g>
                            <circle cx="12" cy="8.5" data-name="&lt;Path&gt;" id="_Path_3" r="3" class="path-style" />
                            <path
                              d="M12,13.5a11.52,11.52,0,0,0-5.14,1.37A2.51,2.51,0,0,0,5.5,17.11v.46a.93.93,0,0,0,.93.94H17.57a.93.93,0,0,0,.93-.94v-.46a2.51,2.51,0,0,0-1.36-2.23A11.52,11.52,0,0,0,12,13.5Z"
                              data-name="&lt;Path&gt;" id="_Path_4" class="path-style" />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </template>
                </MergeFields>
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center">
            <div v-if="enableIcs">
              <span v-b-tooltip.hover title="Add Calender File" class="icon schedule-icon " :class="{ 'color-blue': emailIcsFile != undefined}"
                style="font-size: 21px;margin-right: 10px" @click="onSendICS">
                <i class="fa fa-calendar"></i>
              </span>
            </div>
            <div v-if="enabledSchedule" class="mr-2">
              <span v-b-tooltip.hover :title="scheduleActive ? 'Message Scheduled' : 'Schedule for Later'"
                class="emoji-trigger icon schedule-icon" :class="{ 'active': scheduleActive, 'stroke': !scheduleActive }"
                @click="onScheduleClick">
                <svg id="Layer_1" style="enable-background:new 0 0 64 64;" version="1.1" viewBox="0 0 64 64"
                  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <path class="st0"
                    d="M50,11H10c-1.1,0-2,0.9-2,2v36c0,1.1,0.9,2,2,2h40c1.1,0,2-0.9,2-2V13C52,11.9,51.1,11,50,11z" />
                  <rect class="st1" height="8" width="8" x="15" y="27" />
                  <rect class="st1" height="8" width="8" x="26" y="27" />
                  <rect class="st1" height="8" width="8" x="37" y="27" />
                  <path class="st2" d="M50,11H10c-1.1,0-2,0.9-2,2v8h44v-8C52,11.9,51.1,11,50,11z" />
                  <rect class="st1" height="8" width="8" x="15" y="38" />
                  <rect class="st1" height="8" width="8" x="26" y="38" />
                  <path class="st3" d="M21,16h-3V9.5C18,8.7,18.7,8,19.5,8h0C20.3,8,21,8.7,21,9.5V16z" />
                  <path class="st3" d="M42,16h-3V9.5C39,8.7,39.7,8,40.5,8l0,0C41.3,8,42,8.7,42,9.5V16z" />
                  <polyline class="st4" points="29.2,30.8 31,32.7 34,29.7 " />
                  <g>
                    <circle class="st3" cx="45.5" cy="46.5" r="11.5" />
                  </g>
                  <line class="st5" x1="45.5" x2="53" y1="46.5" y2="46.5" />
                  <line class="st6" x1="43.6" x2="45.5" y1="41.8" y2="46.5" />
                </svg>
              </span>
            </div>
            <button v-if="enableTest" :disabled="disabled || loading || !canMessage"
              class="btn btn-sm btn-primary send-btn pull-right mr-1" @click="handleOpenTest" type="button">
              <span>Test <i class="fa fa-check"></i></span>
            </button>
            <button v-if="showSendButton" :disabled="disabled || loading || !canMessage"
              class="btn btn-sm btn-primary send-btn pull-right" @click="send">
              <atom-spinner v-if="loading" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
              <span v-else>{{ buttonLabel }} <i class="fa fa-send"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <vuestic-modal :isOpen="isOpenModalImage" @cancel="closeModalImage" :cancelShown="false" :okShown="false"
      :large="true">
      <span slot="title">Upload Files</span>
      <div class="">
        <b-tabs v-model="activeMediaTabIndex" small card class="image-tabs">
          <b-tab title="New File" active>
            <DropzoneFileUpload @updated="dropzoneUpdated" ref="dropzoneUpload" :files="files">
            </DropzoneFileUpload>
          </b-tab>
          <b-tab title="Gallery">
            <ImageGallery v-if="activeMediaTabIndex === 1" @imageSelected="onImageSelected" ref="imageGallery">
            </ImageGallery>
          </b-tab>
        </b-tabs>
      </div>

      <div slot="footer" v-if="medias && medias.length">
        <button class="btn btn-danger" @click="removeFiles" type="button">
          <span>Remove Files</span>
        </button>
        <button class="btn btn-primary" @click="closeModalImage" type="button">
          <span>Okay</span>
        </button>
      </div>
    </vuestic-modal>
    <vuestic-modal v-if="enableTest" :isOpen="isShowTestModal" @ok="handleTest" @cancel="isShowTestModal = false"
      okText="Send" cancelText="Cancel" :closeOnOk="false" :processing="isLoadingTest" okClass="btn btn-primary"
      :okDisabled="!testCredential">
      <span slot="title" class="text-primary font-weight-bold">Test</span>

      <p class="font-weight-bold">Please Input Email Addresss to Send Test Mail.</p>
      <text-input type="email" name="Email" placeholder="Enter email" v-model="testCredential" label="Email" />
    </vuestic-modal>
    <vuestic-modal v-if="enabledTemplate" :isOpen="isOpenModalTemplate" @cancel="closeModalTemplate" :cancelShown="false"
      :okShown="false" :large="true">
      <span slot="title">Message Template</span>
      <div class="row">
        <div class="col-md-12">
          <multiselect v-model="selectedTemplate" :options="templatesList" :multiple="false" :close-on-select="true"
            :allow-empty="false" :preserve-search="true" placeholder="Select Template" label="name" track-by="id"
            :preselect-first="true" :show-labels="false">
          </multiselect>
        </div>
        <div class="col-md-12 mt-4" v-if="selectedTemplate">
          <h6 class="font-weight-bold font-italic">Preview</h6>
          <p v-html="selectedTemplate.message"></p>
          <div class="d-flex align-items-center" v-if="selectedTemplate.medias.length">
            <img v-for="(media, key) in selectedTemplate.medias" :key="key" :src="media.path" height="70px"
              class="mr-2" />
          </div>
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" :disabled="!selectedTemplate" @click="onSelectTemplate" type="button">
          <span>Select Template</span>
        </button>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import DropzoneFileUpload from "./DropzoneFileUpload";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import ImageGallery from "./ImageGallery";
import AIContentModal from './AIContentModal'
import MergeFields from "./message/MergeFields";

// froala-editor
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

export default {
  components: {
    DropzoneFileUpload, ImageGallery, AIContentModal, MergeFields,
  },
  props: {
    height: {
      type: Number,
      default: 400,
    },
    value: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    convert: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    enabledMergeField: {
      type: Boolean,
      default: true,
    },
    enabledMms: {
      type: Boolean,
      default: false,
    },
    enabledTemplate: {
      type: Boolean,
      default: false,
    },
    enabledSaveReply: {
      type: Boolean,
      default: false,
    },
    enabledEmoji: {
      type: Boolean,
      default: true,
    },
    enabledMenu: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 1600,
    },
    rows: {
      type: Number,
      default: 3,
    },

    showBottom: {
      type: Boolean,
      default: true,
    },

    showSendButton: {
      type: Boolean,
      default: true,
    },

    contact: {
      type: Object,
      default() {
        return {};
      },
    },
    files: {
      type: Array,
      default: () => {
        return []
      },
    },
    allowImage: {
      type: Boolean,
      default: true,
    },
    enableTest: {
      type: Boolean,
      default: false,
    },
    scheduleActive: {
      type: Boolean,
      default: false,
    },
    emailIcsFile: {
      type: Object,
      default() {
        return undefined;
      },
    },
    enabledSchedule: {
      type: Boolean,
      default: false,
    },
    enableAi: {
      type: Boolean,
      default: false,
    },
    autoGenerate: {
      required: false,
      type: Boolean,
      default: false,
    },
    enableIcs: {
      type: Boolean,
      default: false,
    },
    hideContactMergeFields: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      errors: [],
      mergeFields: [],
      active: false,
      medias: [],
      isOpenModalImage: false,
      isOpenModalTemplate: false,
      selectedTemplate: null,
      templates: [],
      activeMediaTabIndex: 0,
      isShowTestModal: false,
      isLoadingTest: false,
      testCredential: null,
      isShowAIContentModal: false,
      editor: null,
      editorInitialized: false,
      editorConfig: {
        key: process.env.VUE_APP_FROALA_API_KEY,
        heightMin: 100,
        // heightMax: 450,
        quickInsertEnabled: false,
        toolbarVisibleWithoutSelection: false,
        useClasses: false,
        enter: FroalaEditor.ENTER_BR,
        fontFamilySelection: true,
        fontSizeDefaultSelection: '18',
        fontSizeSelection: true,
        linkAlwaysBlank: true,
        linkStyles: {
          'fr-green': 'Green',
          'fr-strong': 'Thick',
          'underlineClass': 'Underline',
        },
        toolbarButtons: {
          'moreText': {
            'buttons': [
              'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'alignLeft', 
              'alignCenter', 'alignRight', 'alignJustify', 'insertLink', 'insertImage', 'emoticons', 'formatUL', 'formatOL', 
              'lineHeight', 'strikeThrough', 'paragraphFormat', 'paragraphStyle', 'insertHR', 'outdent', 'indent', 'quote', 'undo', 'redo', 'fullscreen', 'html'
            ],
            'buttonsVisible': 10,
          },
        },
        emoticonsUseImage: false,
        requestHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        imageUpload: true,
        imageUploadMethod: 'POST',
        imageUploadParam: 'image',
        imageUploadParams: {
          link: true,
        },
        imageUploadRemoteUrls: true,
        imageUploadURL: process.env.VUE_APP_BASE_API + 'api/v1/image',
        ...this.options,
        events: {
          initialized: () => {
            this.initValue()
            this.initEvents()
          },
          blur: () => { 
            this.editor.selection.save();
          },
          destroy: () => { }
        },
      }
    };
  },

  watch: {
    errors: {
      handler: function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          if (newValue.find(item => item.type === 'contact')) {
            this.openContactInfo()
          }
        }
      },
    },
    contact: {
      handler: function () {
        this.initMergeFields();
        this.refreshMessage(this.value);
      },
    },
    businessOwner: {
      handler: function (newValue) {
        if (newValue) {
          this.initMergeFields();
          this.refreshMessage(this.value);
        }
      },
    },
    value: {
      handler: function (newValue, oldValue) {
        if (this.editor && this.value !== this.editor.html.get()) {
          this.initValue()
        }
        this.errors = this.mergeFields.filter(item => {
          return this.value && this.value.includes(item.key)
        })
      }
    },

    mergeFields: {
      handler: function (newValue, oldValue) {
        this.errors = this.mergeFields.filter(item => {
          return this.value && this.value.includes(item.key)
        })
      }
    },
  },

  mounted() {
    if (this.enabledTemplate)
      this.loadTemplates();
    this.initMergeFields()
    this.medias = JSON.parse(JSON.stringify(this.files))
    this.initEditor()
  },

  computed: {

    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },

    buttonLabel() {
      return this.scheduleActive ? 'Schedule Email' : 'Send Email'
    },

    errorMsg() {
      return this.errors.map(item => `${item.type} ${item.key}`).join(', ') + ' are missing.'
    },

    businessOwner() {
      return this.$store.getters["auth/businessOwner"];
    },

    business() {
      return this.$store.getters["auth/business"];
    },

    aiEnabled() {
      return this.$store.getters["auth/aiEnabled"];
    },

    canUseAi() {
      return this.aiEnabled && this.enableAi
    },

    canMessage() {
      return this.value && this.value.length && !this.errors.length
    },

    templatesList() {
      return this.templates.map((item) => item);
    },

    vid() {
      return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(2, 10);
    },
  },

  methods: {  
    initValue() {
      this.editor.html.set(`<div style="font-size: 18px">${this.value ? this.value: ''}</div>`)
    },
    initEditor() {
      if (!this.editorInitialized) {
        this.editor = new FroalaEditor(this.$refs.editor, this.editorConfig)
        this.editorInitialized = true
      }
    },
    initEvents() {
      let _this = this
      _this.editor.events.on('contentChanged', function (e) {
        if (_this.value !== _this.editor.html.get()) {
          _this.$emit('input', _this.editor.html.get())
          _this.$emit('changed', _this.editor.html.get())
        }
      }, false);

    },

    getValueFromMergeField(key) {
      const result = this.mergeFields.find((item) => item.key == key)
      return result && result.value ? result.value : ''
    },

    onAddMergeField({ value, type }) {
      let textValue = value
      if (this.convert) {
        let tmp = this.getValueFromMergeField(value)
        if (tmp) textValue = tmp
      }
      
      this.editor.selection.restore()
      this.editor.html.insert(textValue)
    },
    
    onAddAIContent({subject, content}) {
      this.refreshMessage(content.replaceAll('\n', '<br/>'))
      this.isShowAIContentModal = false
      if (subject)
        this.$emit("onSubject", subject)
    },
    onScheduleClick() {
      this.$emit("onSchedule", {})
    },

    loadTemplates() {
      let params = {
        provider: 'email'
      }
      this.$store
        .dispatch("template/all", params)
        .then((res) => {
          this.templates = res;
        })
        .catch(() => { });
    },
    onSelectTemplate() {
      this.activeMediaTabIndex = 0
      this.refreshMessage(this.selectedTemplate.message);
      this.removeFiles();
      this.$nextTick(() => {
        this.$refs.dropzoneUpload.manuallyAddFile([
          ...this.selectedTemplate.medias,
        ]);
        this.closeModalTemplate();
        this.$emit('done', this.selectedTemplate.medias)
      })
    },

    onOpenModalTemplate() {
      this.selectedTemplate = null;
      this.isOpenModalTemplate = true;
    },

    closeModalTemplate() {
      this.isOpenModalTemplate = false;
    },

    onImageSelected(files) {
      this.medias = files
    },

    openContactInfo() {
      this.$emit('onOpenContactInfo')
    },

    send() {
      if (this.canMessage) {
        this.$emit("send", {
          type: 'email',
          files: [...this.medias]
        });
      } else {
        Vue.$toast.open({
          message: "Message is required!",
          type: "default",
        });
      }
    },

    refreshMessage(msg) {
      let tmp = msg;
      if (this.convert) {
        this.mergeFields.forEach((item) => {
          if (item.value) {
            tmp = tmp.replaceAll(item.key, item.value)
          }
        });
      }
      this.editor.html.set(tmp)
    },

    initMergeFields() {
      if (this.businessOwner) {
        const { properties } = this.businessOwner;
        const { first_name, last_name, number, email, bday_str, anniv_str, city, note, custom_fields } = this.contact;

        let myPhoneNumber = ''
        let phoneNumber = ''
        if (this.businessOwner.phone) {
          try {
            myPhoneNumber = parsePhoneNumberFromString(this.businessOwner.phone);
            myPhoneNumber = myPhoneNumber.formatNational()
          } catch (error) { }
        }
        if (number) {
          try {
            phoneNumber = parsePhoneNumberFromString(number);
            phoneNumber = phoneNumber.formatNational()
          } catch (error) { }
        }

        this.mergeFields = [
          { key: '[my_first_name]', type: 'my', value: properties.firstName },
          { key: '[my_last_name]', type: 'my', value: properties.lastName },
          { key: '[my_phone_number]', type: 'my', value: myPhoneNumber },
          { key: '[my_email]', type: 'my', value: this.businessOwner.email },
          { key: '[business_name]', type: 'my', value: this.business.name },
          { key: '[first_name]', type: 'contact', value: first_name },
          { key: '[last_name]', type: 'contact', value: last_name },
          { key: '[phone_number]', type: 'contact', value: phoneNumber },
          { key: '[email]', type: 'contact', value: email },
          { key: '[birthday]', type: 'contact', value: bday_str },
          { key: '[anniversary]', type: 'contact', value: anniv_str },
          { key: '[city]', type: 'contact', value: city },
          { key: '[note]', type: 'contact', value: note },
        ]

        this.contactFields.forEach((item) => {
          let value = '';
          if (custom_fields && custom_fields.hasOwnProperty(item.id)) {
            value = custom_fields[item.id]
          }
          this.mergeFields.push({ key: `[custom_${item.name}]`, type: 'contact', value });
        })
      }
    },
    onOpenImageModal() {
      this.isOpenModalImage = true;
    },
    closeModalImage() {
      this.isOpenModalImage = false;
      this.$emit('done', this.medias)
    },
    sendingFile(file, xhr, formData) { },

    removeFiles() {
      this.$refs.dropzoneUpload.removeAllFiles();
    },

    dropzoneUpdated(data) {
      const { medias } = data;
      this.medias = medias;
    },

    handleOpenTest() {
      this.isShowTestModal = true
    },

    handleTest() {
      this.isLoadingTest = true
      let attachments = []
      attachments = [...this.medias];
      if(this.emailIcsFile != undefined) {
          attachments.push(this.emailIcsFile)
      } 
      let params = {
        email: this.testCredential,
        content: this.value,
        attachments: attachments,
      }

      this.$store.dispatch('automation/testEmail', params).then((res) => {
        this.isShowTestModal = false
        this.isLoadingTest = false
        this.testCredential = null
      })
        .catch(() => {
          this.isLoadingTest = false
        })
    },
    onSendICS() {
      this.$emit("onEmailSendICS", {});
    },
  },
};
</script>

<style lang="scss">
.texteditor-picker {
  .fr-box.fr-basic .fr-element {
    font-size: 18px;
  }  
  .schedule-icon {
    .st0 {
      fill: #fff;
      stroke: #333;
      stroke-width: 2;
    }

    .st1 {
      fill: #FFFFFF;
      stroke: #333;
      stroke-width: 2;
    }

    .st2 {
      fill: #fff;
      stroke: #333;
      stroke-width: 2;
    }

    .st3 {
      fill: #fff;
      stroke: #333;
      stroke-width: 2;
    }

    .st4 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st5 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st6 {
      fill: none;
      stroke: #333;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .st7 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #fff;
    }

    .st8 {
      fill: none;
      stroke: #333;
      stroke-width: 5;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st9 {
      fill: none;
      stroke: #333;
      stroke-width: 3;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st10 {
      fill: none;
      stroke: #333;
      stroke-width: 3;
      stroke-miterlimit: 10;
    }

    .st11 {
      fill: none;
      stroke: #333;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }

    .st12 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .st13 {
      fill: none;
      stroke: #333;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st14 {
      fill: none;
      stroke: #333;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st15 {
      fill: none;
      stroke: #333;
      stroke-width: 3;
      stroke-miterlimit: 10;
    }

    .st16 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st17 {
      fill: none;
      stroke: #333;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st18 {
      fill: none;
      stroke: #333;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st19 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st20 {
      fill: none;
      stroke: #333;
      stroke-width: 12.605;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st21 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .st22 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-linecap: square;
      stroke-miterlimit: 10;
    }

    .st23 {
      fill: none;
      stroke: #333;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }

    .st24 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .st25 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #fff;
    }

    .st26 {
      fill: none;
      stroke: #333;
      stroke-width: 4;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st27 {
      fill: none;
      stroke: #333;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke-dasharray: 2, 2;
    }

    &.active {
      .st0 {
        fill: #D9DCE1;
        stroke: none;
      }

      .st1 {
        fill: #FFFFFF;
        stroke: none;
      }

      .st2 {
        fill: #56AAFF;
        stroke: none;
      }

      .st3 {
        fill: #0478ED;
        stroke: none;
      }

      .st4 {
        fill: none;
        stroke: #0478ED;
        stroke-width: 2;
        stroke-linecap: square;
        stroke-miterlimit: 10;
      }

      .st5 {
        fill: none;
        stroke: #D9DCE1;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st6 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .st7 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #0478ED;
      }

      .st8 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 5;
        stroke-linecap: square;
        stroke-miterlimit: 10;
      }

      .st9 {
        fill: none;
        stroke: #0478ED;
        stroke-width: 3;
        stroke-linecap: square;
        stroke-miterlimit: 10;
      }

      .st10 {
        fill: none;
        stroke: #0478ED;
        stroke-width: 3;
        stroke-miterlimit: 10;
      }

      .st11 {
        fill: none;
        stroke: #D9DCE1;
        stroke-width: 4;
        stroke-miterlimit: 10;
      }

      .st12 {
        fill: none;
        stroke: #D9DCE1;
        stroke-width: 2;
        stroke-miterlimit: 10;
      }

      .st13 {
        fill: none;
        stroke: #56AAFF;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st14 {
        fill: none;
        stroke: #56AAFF;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st15 {
        fill: none;
        stroke: #D9DCE1;
        stroke-width: 3;
        stroke-miterlimit: 10;
      }

      .st16 {
        fill: none;
        stroke: #56AAFF;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st17 {
        fill: none;
        stroke: #0478ED;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st18 {
        fill: none;
        stroke: #56AAFF;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st19 {
        fill: none;
        stroke: #0478ED;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st20 {
        fill: none;
        stroke: #D9DCE1;
        stroke-width: 12.605;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st21 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .st22 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-linecap: square;
        stroke-miterlimit: 10;
      }

      .st23 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .st24 {
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 2;
        stroke-miterlimit: 10;
      }

      .st25 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #56AAFF;
      }

      .st26 {
        fill: none;
        stroke: #0478ED;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-miterlimit: 10;
      }

      .st27 {
        fill: none;
        stroke: #0478ED;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke-dasharray: 2, 2;
      }
    }
  }

  .mergefield-wrapper {
    padding: 5px 0px;

    .mergefield-tags {
      cursor: pointer;
      font-size: 12px;
    }
  }

  .textarea-emoji-picker {
    position: relative;
    margin: 0 auto;
    margin-top: 7px;
  }

  .emoji-trigger {
    cursor: pointer;

    .fa {
      color: #808080;
      vertical-align: middle;

      &:hover {
        polyline {
          color: darken($rb-blue, 15%);
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    path {
      transition: 0.1s all;
    }

    .path-style {
      fill: none;
      stroke: #333;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    &.merge-filed-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &.img-icon {
      svg {
        width: 22px;
        height: 22px;
      }
    }

    &.save-reply-icon,
    &.schedule-icon {
      svg {
        width: 28px;
        height: 28px;
      }
    }

    &.fill {
      &.has-medias {

        path,
        circle,
        line,
        rect,
        polyline {
          fill: $rb-blue;
        }
      }

      &:hover {

        path,
        circle,
        line,
        rect,
        polyline {
          fill: darken($rb-blue, 15%);
        }
      }

      &.triggered {

        path,
        circle,
        line,
        rect,
        polyline {
          fill: darken(#fec84a, 15%);
        }
      }
    }

    &.stroke {
      &.has-medias {

        path,
        circle,
        line,
        rect,
        polyline {
          stroke: $rb-blue;
        }
      }

      &:hover {

        path,
        circle,
        line,
        rect,
        polyline {
          stroke: darken($rb-blue, 15%);
        }
      }

      &.triggered {

        path,
        circle,
        line,
        rect,
        polyline {
          stroke: darken(#fec84a, 15%);
        }
      }
    }
  }
  .fr-second-toolbar {
    display: none;
  }
  .send-btn {
    border-radius: 0 !important;
  }

  .message-compose-wrapper {
    border: 1px solid #dee2e6;
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    height: 36px;

    ul.icon-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-left: 7px;

      >li {
        position: relative;
        display: inline-block;
        padding: 0 5px;

        span.icon {
          font-size: 21px;
          padding: 0 5px;
          cursor: pointer;
          &:hover .fa{
            color: $rb-blue;
          }
        }

        span.badge {
          min-width: 1.2rem;
          border-radius: 50%;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
    }
  }
  .color-blue {
    color: #0478ED !important;
  }
}
</style>